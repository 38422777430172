<template>
  <div class="h-full">
    <loader :loading="loading" :backdrop="true" />
    <div class="form-row w-full">
      <FinalForm
        ref="form"
        @submit="saveData"
        :initialValues="initialValues"
        class="w-full pr-2"
      >
        <div class="stick bg-white pb-3 z-10">
          <div class="form-row">
            <label class="font-semibold" style="color: var(--highlightColor500)"
              >lease terms
            </label>
          </div>
        </div>
        <div>
          <div class="form-row">
            <TextField
              name="apartmentNumber"
              label="Apartment Number"
              type="number"
            />
            <TextField name="dateOfLease" label="Date Of Lease" type="date" />
          </div>
          <div class="form-row">
            <TextField
              name="addressLine1"
              label="street line 1"
              :validate="required"
            />
          </div>
          <div class="form-row">
            <TextField name="addressLine2" label="street line 2" />
          </div>
          <div class="form-row">
            <TextField name="city" label="city" :validate="required" />
            <StateInput name="state" label="state" :validate="required" />
            <TextField name="zip" label="zip" :validate="required" />
          </div>

          <div class="h-4"></div>
          <label class="form-section-sub-title">residential information</label>
          <div class="form-row pb-1">
            <TextField name="parties1" label="parties" />
            <TextField name="occupants1" label="Occupants" />
          </div>
          <div class="form-row pb-1">
            <TextField name="parties2" />
            <TextField name="occupants2" />
          </div>
          <div class="form-row pb-1">
            <TextField name="parties3" />
            <TextField name="occupants3" />
          </div>
          <div class="form-row pb-1">
            <TextField name="parties4" />
            <TextField name="occupants4" />
          </div>
          <div class="form-row w-1/2 pb-1 pr-8">
            <TextField name="parties5" />
          </div>
          <div class="form-row w-1/2 pb-1 pr-8">
            <TextField name="parties6" />
          </div>

          <div class="h-4"></div>

          <div class="flex py-4 items-center">
            <div class="mr-1 text-title">
              consent necessery for guests for more than
            </div>
            <TextInputSmall class="text-title" name="guestsDays" />
            <div class="ml-1 text-title">days</div>
          </div>

          <div class="h-4"></div>
          <label class="form-section-sub-title">lease terms</label>
          <div class="form-row mb-4">
            <TextField label="Begins on" name="beginsOn" type="date" />
            <TextField label="Monthly Rent" name="monthlyRent" type="number" />
          </div>
          <div class="form-row mb-4">
            <TextField label="Ends on" name="endsOn" type="date" />
            <TextField
              label="Security Deposit"
              name="securityDeposit"
              type="number"
            />
          </div>
          <div class="form-row mb-4">
            <TextField
              label="Length of lease"
              name="lengthOfLease"
              type="number"
            />
            <TextField
              label="Prorated rent"
              name="ProratedRent"
              type="number"
            />
            <TextField label="days" name="ProratedRentDays" type="number" />
            <TextField label="at" name="ProratedRentAt" type="number" />
          </div>
          <div class="form-row mb-4" style="width: max-content">
            <div class="col-span-3 form-col">
              <label class="">include pet deposit</label>
              <toggle-buttons
                v-model="petToggleValue"
                :options="toggleOptionsPet"
              />
            </div>
            <div class="col-span-3 form-col">
              <label class="">due for the remainder of</label>
              <toggle-buttons
                v-model="remainderselectedToggle"
                :options="toggleOptions"
              />
            </div>
          </div>
          <div class="form-row mb-4">
            <TextField label="on" name="remainderOn" type="date" />
            <TextField
              label="reletting Charge"
              name="remainderCharge"
              type="number"
            />
            <TextInputSmall
              style="margin-top: 28px"
              placeholder="%"
              name="remainderPercentage"
              type="number"
            />
          </div>
          <div class="h-4"></div>

          <div class="h-4"></div>
          <label class="form-section-sub-title">
            security deposit refund check payable</label
          >
          <div class="form-row">
            <button>
              <CheckboxInputSmall
                type="checkbox"
                name="checkPayableToAll"
                style="margin-left: 0"
              />
            </button>
            <div class="text-title mr-8">
              one check jointly payable to all residents
            </div>
            <button>
              <CheckboxInputSmall
                type="checkbox"
                name="checkPayableTo"
                style="margin-left: 0"
              />
            </button>
            <div class="text-title">one check payable to</div>
            <TextField
              name="securityCheckEmail"
              class="ml-4"
            />
          </div>
        </div>
      </FinalForm>
      <modal-footer :footer="footer" :primary="saveData"></modal-footer>
    </div>
  </div>
</template>

<script>
import ModalNavigation from "@/mixins/ModalNavigation";
import Loader from "@/components/ui/Loader";
import TextField from "@/components/form/TextField";
import TextInputSmall from "@/components/form/TextInputSmall";
import ToggleButtons from "@/components/ui/ToggleButtons";
import StateInput from "@/components/form/StateInput";
import { FinalForm } from "vue-final-form";
import InitializeFormMixin from "@/components/form/InitializeFormMixin";
import ModalFooter from "@/components/ui/modals/ModalFooter";
import CheckboxInputSmall from "@/components/form/CheckboxInputSmall";

export default {
  mixins: [ModalNavigation, InitializeFormMixin],
  components: {
    Loader,
    TextField,
    TextInputSmall,
    ToggleButtons,
    StateInput,
    FinalForm,
    ModalFooter,
    CheckboxInputSmall,
  },
  data() {
    return {
      apartmentNumber: "",
      dateOfLease: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      zip: "",
      parties1: "",
      occupants1: "",
      parties2: "",
      occupants2: "",
      parties3: "",
      occupants3: "",
      parties4: "",
      occupants4: "",
      parties5: "",
      parties6: "",
      guestsDays: "",
      beginsOn: "",
      monthlyRent: "",
      endsOn: "",
      securityDeposit: "",
      lengthOfLease: "",
      ProratedRent: "",
      ProratedRentDays: "",
      ProratedRentAt: "",
      petToggleValue: 1,
      remainderselectedToggle: 1,
      remainderOn: "",
      remainderCharge: "",
      remainderPercentage: "",
      timezones: [],
      loading: false,
      toggleOptionsPet: [
        { value: 1, label: "yes" },
        { value: 2, label: "no" },
      ],
      toggleOptions: [
        { value: 1, label: "1stMonth" },
        { value: 2, label: "2ndMonth" },
      ],
      checkPayableToAll: "",
      checkPayableTo: "",
      securityCheckEmail: "",
      footer: {
        primaryButton: "save",
      },
    };
  },
  computed: {
    initialValues() {
      return {
        apartmentNumber: "",
        dateOfLease: "",
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: "",
        zip: "",
        parties1: "",
        occupants1: "",
        parties2: "",
        occupants2: "",
        parties3: "",
        occupants3: "",
        parties4: "",
        occupants4: "",
        parties5: "",
        parties6: "",
        guestsDays: "",
        beginsOn: "",
        monthlyRent: "",
        endsOn: "",
        securityDeposit: "",
        lengthOfLease: "",
        ProratedRent: "",
        ProratedRentDays: "",
        ProratedRentAt: "",
        petToggleValue: 1,
        remainderselectedToggle: 1,
        remainderOn: "",
        remainderCharge: "",
        remainderPercentage: "",
        checkPayableToAll: "",
        checkPayableTo: "",
        securityCheckEmail: "",
      };
    },
  },
  methods: {
    saveData() {
      if (
        !this.$refs.form.formState.values.checkPayableToAll &&
        !this.$refs.form.formState.values.checkPayableTo
      ) {
        this.notifyError("Please select one of the checkboxes.");
      }
    },
  },
};
</script>

<style scoped>
.stick {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}
button {
  outline: none;
  box-shadow: none;
}
</style>

